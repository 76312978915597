import { MenuItem, Grid, TextField, FormControlLabel, Checkbox, Autocomplete } from '@mui/material';
import Iconify from './Iconify';

const FormField = ({
  data,
  formData = {},
  handleChange = () => {},
  formDisabled = false,
  isEdit = false,
  isUploading = false,
}) => {
  if (!data) return;

  const handleChangeAutocomplete = (value, name) => {
    const data = {
      target: {
        name,
        value,
      },
    };
    handleChange(data);
  };

  const getInputLabelProps = (type) =>
    type === 'date' || type === 'number' || type === 'file' ? { shrink: true } : {};

  const getInputProps = (type, accept) => (type === 'file' ? { accept: accept } : {});

  const {
    options = [],
    accept = 'image/*',
    id,
    size = 6,
    required = false,
    disabled = false,
    multiline = false,
    label = '',
    name = '',
    type = 'text',
    hint = '',
    inputSize = '',
    hideEdit = false,
    disableEdit = false,
  } = data || {};

  if (hideEdit && isEdit) return;

  const value = type !== 'file' ? { value: formData[name] ? formData[name] : type === 'number' ? 1 : '' } : {};

  if (type === 'autocomplete') {
    return (
      <Grid key={id} item xs={12} sm={size} sx={{ position: 'relative' }}>
        <Autocomplete
          freeSolo
          options={options}
          name={type === 'file' ? `file-${name}` : name}
          onInputChange={(_, newValue) => handleChangeAutocomplete(newValue, name)}
          inputValue={formData?.[name] ? formData?.[name] : ''}
          fullWidth
          renderInput={(params) => (
            <TextField
              name={type === 'file' ? `file-${name}` : name}
              {...params}
              required={required}
              label={label}
              helperText={hint}
            />
          )}
        />
      </Grid>
    );
  } else if (type === 'checkbox') {
    return (
      <Grid key={id} item xs={12} sm={size} justifyContent="center" sx={{ display: 'flex' }}>
        <FormControlLabel
          control={<Checkbox checked={formData[name] ? true : false} name={name} onChange={handleChange} />}
          label={label}
          labelPlacement="top"
        />
      </Grid>
    );
  } else {
    return (
      <Grid key={id} item xs={12} sm={size} sx={{ position: 'relative' }}>
        {isUploading && type === 'file' && (
          <Grid sx={{ position: 'absolute', top: '50px', right: '15px' }}>
            <Iconify sx={{ color: '#1939B7' }} width={20} height={20} icon="eva:loader-outline" />
          </Grid>
        )}
        <TextField
          hidden
          fullWidth
          required={required}
          label={label}
          name={type === 'file' ? `file-${name}` : name}
          id={name}
          type={type}
          size={inputSize || 'medium'}
          disabled={formDisabled || (disableEdit && isEdit) || disabled}
          helperText={hint}
          {...value}
          onChange={handleChange}
          select={type === 'select' ? true : false}
          minRows={3}
          multiline={multiline}
          InputLabelProps={getInputLabelProps(type)}
          inputProps={getInputProps(type, accept)}
        >
          {type === 'select' &&
            options.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
        </TextField>
      </Grid>
    );
  }
};

export default FormField;
