import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

export default function LogoOnlyLayout() {
  const { user } = useAuth();

  if (user) {
    return <Navigate to="/tableau-de-bord" />;
  }
  return (
    <>
      <Outlet />
    </>
  );
}
