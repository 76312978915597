import { Paper, Typography } from '@mui/material';

export default function SearchNotFound({ searchQuery = '', loading = false, ...other }) {
  return (
    <Paper {...other}>
      {loading ? (
        <Typography gutterBottom align="center" variant="subtitle1">
          Chargement ...
        </Typography>
      ) : (
        <>
          <Typography gutterBottom align="center" variant="subtitle1">
            Pas de résultats
          </Typography>
        </>
      )}
    </Paper>
  );
}
