import { Container, Typography, Stack, Button, Card, Grid } from '@mui/material';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Page from 'src/components/Page';
import Iconify from '../components/Iconify';
import urlSlug from 'url-slug';
import axios from 'axios';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'draft-js/dist/Draft.css';
import FormField from 'src/components/FormField';

const formShape = [
  { id: 0, required: false, type: 'file', label: 'Image Article *', name: 'thumbnail', size: 12 },
  { id: 1, required: true, type: 'text', label: 'Titre', name: 'title', size: 12 },

  {
    id: 2,
    required: true,
    type: 'text',
    label: 'URL Slug',
    name: 'slug',
    size: 12,
    hint: "La dernière partie de l'URL. ex: /bonjour-le-monde",
  },
  {
    id: 3,
    required: true,
    type: 'text',
    multiline: true,
    label: 'Résumé',
    name: 'description',
    size: 12,
  },
  { id: 4, required: false, type: 'file', label: 'Mini Image 1', name: 'image1', size: 12 },
  { id: 5, required: false, type: 'file', label: 'Mini Image 2', name: 'image2', size: 12 },
  { id: 6, required: false, type: 'file', label: 'Mini Image 3', name: 'image3', size: 12 },
];

const BlogManage = () => {
  const { id } = useParams();
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [isUploading, setIsUploading] = useState(false);

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);

    if (id !== 'ajout') {
      axios
        .get(`/api/posts?slug=${id}`)
        .then((result) => {
          const postData = result?.data?.['hydra:member']?.[0] || {};
          // console.log(postData);
          setIsEdit(true);
          const blocksFromHtml = convertFromHTML(postData.content);

          const { contentBlocks, entityMap } = blocksFromHtml;
          const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
          const newEditorState = EditorState.createWithContent(contentState);

          setEditorState(newEditorState);

          setFormData(postData);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.error(err);
        });
    } else {
      setIsLoading(false);
    }
  }, [id]);

  const getTitle = () => (isEdit ? 'Article' : 'Ajout Article');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData?.content || formData?.content === '<p></p>\n') {
      alert('Contenue obligatoire');
      return;
    }
    if (!formData?.thumbnail) {
      alert('Image obligatoire');
      return;
    }

    setIsLoading(true);

    if (isEdit) {
      axios
        .patch(`/api/posts/${formData.id}`, formData, {
          headers: {
            'Content-Type': 'application/merge-patch+json',
          },
        })
        .then((res) => {
          navigate('/articles');
        })
        .catch((e) => {
          console.error(e);
          setIsLoading(false);
        });
    } else {
      formData.date = new Date();

      axios
        .post('/api/posts', formData)
        .then((res) => {
          navigate('/articles');
        })
        .catch((e) => {
          console.error(e);
          setIsLoading(false);
        });
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    navigate('/articles');
  };
  const handleChange = (e) => {
    const { name, value, type, checked, id, files } = e.target;

    if (name === 'title') {
      setFormData((prev) => ({ ...prev, [name]: value, slug: urlSlug(value) }));
      return;
    }

    if (type === 'checkbox') {
      setFormData((prev) => ({ ...prev, [name]: checked }));
    } else if (type === 'file') {
      const filename = files?.[0]?.name;
      const fileExt = filename.split('.').pop();

      setIsUploading(true);
      const fdt = new FormData();

      fdt.append('document', files?.[0]);
      fdt.append('fileName', `image_article_${new Date().getTime()}`);
      fdt.append('extension', fileExt);

      axios
        .post('/api/upload_document', fdt, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          const imagePath = res?.data?.['hydra:member']?.[0];

          if (imagePath) {
            setIsUploading(false);
            setFormData((prev) => ({ ...prev, [id]: imagePath }));
          }
        })
        .catch((e) => {
          console.log(e);
          setIsUploading(false);
        });
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    const editorHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setFormData((prev) => ({ ...prev, content: editorHTML }));
  };
  return (
    <Page title={getTitle()}>
      <Container maxWidth={false}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {getTitle()}
          </Typography>
          <div>
            <Button
              onClick={handleBack}
              color="secondary"
              sx={{ marginRight: 5 }}
              startIcon={<Iconify icon="eva:arrow-back-outline" />}
            >
              Retour
            </Button>
            <Button
              disabled={!!isLoading}
              type="submit"
              form="formblog"
              variant="contained"
              color="secondary"
              startIcon={<Iconify icon={isLoading ? 'eva:loader-outline' : 'eva:save-outline'} />}
            >
              {isEdit ? 'Mettre à jour' : 'Enregistrer'}
            </Button>
          </div>
        </Stack>

        <Grid container spacing={3} as="form" id="formblog" onSubmit={handleSubmit}>
          <Grid item xs={12} sm={12} md={8}>
            <Card>
              <Grid p={2}>
                <Typography variant="h5" gutterBottom mb={3}>
                  Contenu
                </Typography>
                <Editor
                  toolbar={{
                    options: [
                      'inline',
                      'blockType',
                      'fontSize',
                      'list',
                      'textAlign',
                      'colorPicker',
                      'link',
                      'emoji',
                      'image',
                      'remove',
                      'history',
                    ],
                    inline: {
                      options: ['bold', 'italic', 'underline', 'strikethrough'],
                    },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                  }}
                  editorClassName="wisyblog"
                  hashtag={{}}
                  onEditorStateChange={onEditorStateChange}
                  editorState={editorState}
                />
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Card>
              <Grid container spacing={2} p={2}>
                {formShape.map((form) => (
                  <FormField
                    data={form}
                    key={form.id}
                    isUploading={isUploading}
                    formData={formData}
                    handleChange={handleChange}
                  />
                ))}
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default BlogManage;
