import {
  Container,
  Stack,
  Typography,
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
} from '@mui/material';
import Page from '../components/Page';
import { applySortFilter, getComparator } from 'src/utils/functions';
import { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';

const TABLE_HEAD = [
  { id: 'nom', label: 'Nom', alignRight: false },
  { id: 'prenom', label: 'Prénom', alignRight: false },
  { id: 'datenaissance', label: 'Date et lieu de naissance', alignRight: false },
  { id: 'phone', label: 'Téléphone', alignRight: false },
  { id: 'whatsapp', label: 'Whatsapp', alignRight: false },
  { id: 'adress', label: 'Adresse', alignRight: false },
  { id: 'situation', label: 'Situation Matrimoniale', alignRight: false },
];

export default function Conference() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('title');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get('/api/conferences')
      .then((res) => {
        const conferenceData = res?.data?.['hydra:member'] || [];
        setPosts(conferenceData);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const getSituation = (situation = '', nbrEnfant = '0') => {
    if (
      situation === 'Célibataire avec enfants' ||
      situation === 'Divorcé avec enfants' ||
      situation === 'Marié avec enfants'
    ) {
      return `${situation} (${nbrEnfant})`;
    }

    return situation;
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - posts.length) : 0;

  const filteredPosts = applySortFilter(posts, getComparator(order, orderBy), filterName, 'nom', 'prenom');

  const isPostNotFound = filteredPosts.length === 0;

  return (
    <Page title="Conférences">
      <Container maxWidth={false}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Conférences
          </Typography>
        </Stack>

        <Card>
          <UserListToolbar
            placeholder="Chercher inscription..."
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredPosts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const {
                      id,
                      nom,
                      prenom,
                      datenaissance,
                      lieunaissance,
                      phone,
                      whatsapp,
                      residence,
                      nombreenfant,
                      situaionmatrimoniale,
                    } = row;

                    return (
                      <TableRow hover key={id} tabIndex={-1}>
                        <TableCell align="left">{nom}</TableCell>
                        <TableCell align="left">{prenom}</TableCell>
                        <TableCell align="left">{`${moment(datenaissance).format(
                          'DD MMM YYYY'
                        )} à ${lieunaissance}`}</TableCell>
                        <TableCell align="left">{phone}</TableCell>
                        <TableCell align="left">{whatsapp}</TableCell>
                        <TableCell align="left">{residence}</TableCell>
                        <TableCell align="left">{getSituation(situaionmatrimoniale, nombreenfant)}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isPostNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} loading={loading} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={posts.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
