import { Link as RouterLink } from 'react-router-dom';
import {
  Button,
  Container,
  Stack,
  Typography,
  Card,
  Table,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
} from '@mui/material';
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import { applySortFilter, getComparator } from 'src/utils/functions';
import { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';

const TABLE_HEAD = [
  { id: 'title', label: 'Titre', alignRight: false },
  { id: 'description', label: 'Résumé', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
  { id: 0 },
];

export default function Blog() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('title');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get('/api/posts')
      .then((res) => {
        const blogsData = res?.data?.['hydra:member'] || [];
        setPosts(blogsData);
        // console.log(blogsData);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleDelete = (postId) => {
    if (postId) {
      setLoading(true);

      axios
        .delete(`/api/posts/${postId}`)
        .then((res) => {
          const newPosts = posts.filter((post) => post.id !== postId);
          setPosts(newPosts);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - posts.length) : 0;

  const filteredPosts = applySortFilter(posts, getComparator(order, orderBy), filterName, 'title', 'description');

  const isPostNotFound = filteredPosts.length === 0;

  return (
    <Page title="Articles">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Articles
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/articles/ajout"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Ajouter
          </Button>
        </Stack>

        <Card>
          <UserListToolbar
            placeholder="Chercher article..."
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredPosts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, title, description, thumbnail, slug, date } = row;
                    const postImg = thumbnail
                      ? `${process.env.REACT_APP_API_URL}/images/${thumbnail}`
                      : '/static/mock-images/avatars/avatar_default.jpg';

                    return (
                      <TableRow hover key={id} tabIndex={-1}>
                        <TableCell component="th" scope="row">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={title} src={postImg} />
                            <Typography variant="subtitle2" noWrap>
                              {title}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{description}</TableCell>
                        <TableCell align="left">{moment(date).format('DD MMM YYYY')}</TableCell>

                        <TableCell align="right">
                          <UserMoreMenu url="/articles" userName={slug} userId={id} handleDelete={handleDelete} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isPostNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} loading={loading} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={posts.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
