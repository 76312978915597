import axios from 'axios';
import { useEffect, useState } from 'react';
import { Grid, Container } from '@mui/material';

import Page from '../components/Page';

import { AppNewsUpdate, AppWidgetSummary } from '../sections/@dashboard/app';
import { CircularProgress } from '@mui/material';

export default function DashboardApp() {
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState({});
  const [conferencesData, setConferencesData] = useState(0);

  useEffect(() => {
    setIsLoading(true);

    const promiseArray = [axios.get('/api/posts'), axios.get('/api/conferences')];

    Promise.allSettled(promiseArray)
      .then((res) => {
        const dataPost = res?.[0]?.value?.data || {};
        const dataConf = res?.[1]?.value?.data || {};

        setDashboardData({
          items: dataPost?.['hydra:member'] || [],
          total: dataPost?.['hydra:totalItems'] || 0,
        });

        setConferencesData(dataConf?.['hydra:totalItems'] || 0);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        console.error(e);
      });
  }, []);

  return (
    <Page title="Tableau de bord">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3} md={3}>
            <AppWidgetSummary
              title="Articles"
              total={dashboardData?.total || 0}
              color="info"
              icon={'ant-design:file-text-outlined'}
            />
            <AppWidgetSummary
              title="Conférences"
              total={conferencesData || 0}
              color="secondary"
              icon={'ant-design:file-text-outlined'}
            />
          </Grid>

          {isLoading ? (
            <Grid item xs={12} md={9} lg={9} justifyContent="center" sx={{ display: 'flex' }}>
              <CircularProgress />
            </Grid>
          ) : (
            dashboardData?.total > 0 && (
              <Grid item xs={12} md={9} lg={9}>
                <AppNewsUpdate
                  title="Dernier Articles"
                  list={dashboardData?.items
                    .filter((_, idx) => idx < 5)
                    .map((post) => ({
                      id: post?.id,
                      title: post?.title,
                      slug: post?.slug,
                      description: post?.description,
                      image: `${process.env.REACT_APP_API_URL}/images/${post?.thumbnail}`,
                      postedAt: post?.date,
                    }))}
                />
              </Grid>
            )
          )}
        </Grid>
      </Container>
    </Page>
  );
}
