import * as Yup from 'yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import axios from 'axios';
import md5 from 'md5';
import { useAuth } from '../../../context/AuthContext';

export default function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);

  const { login } = useAuth();

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required("Le nom d'utilisateur est obligatoire."),
    password: Yup.string().required('Le mots de passe est obligatoire.'),
  });

  const defaultValues = {
    username: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (dataForm) => {
    const { username, password } = dataForm || {};
    const md5Password = md5(password);

    const res = await axios.get(`/api/users?username=${username}&password=${md5Password}`);
    const data = res?.data?.['hydra:member'];

    if (data.length > 0) {
      const userData = data?.[0];

      login(userData);
    } else {
      setError('username', { type: 'custom', message: "Nom d'utilisateur non valide." }, { shouldFocus: true });
      setError('password', { type: 'custom', message: 'Mots de passe erroné.' }, { shouldFocus: true });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} mb={3}>
        <RHFTextField name="username" label="Nom d'utilisateur" />
        <RHFTextField
          name="password"
          label="Mots de passe"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Connexion
      </LoadingButton>
    </FormProvider>
  );
}
