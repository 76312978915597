import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import Conference from './pages/Conference';
// import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
// import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
// import UserManage from './pages/UserManage';
import BlogManage from './pages/BlogManage';
// import Loading from './pages/Loading';
// import ProductManage from './pages/ProductManage';
// import Product from './pages/Product';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        // { path: '', element: <Loading /> },
        { path: '', element: <Navigate to="/tableau-de-bord" /> },
        { path: 'tableau-de-bord', element: <DashboardApp /> },
        // { path: 'utilisateurs', element: <User /> },
        // { path: 'utilisateurs/:id', element: <UserManage /> },
        { path: 'conferences', element: <Conference /> },
        { path: 'articles', element: <Blog /> },
        { path: 'articles/:id', element: <BlogManage /> },
        // { path: 'produits', element: <Product /> },
        // { path: 'produits/:id', element: <ProductManage /> },
      ],
    },
    // { path: '/', element: <LogoOnlyLayout />, children: [{ path: '', element: <Loading /> }] },
    // { path: '/', element: <LogoOnlyLayout />, children: [{ path: '', element: <Loading /> }] },
    // { path: '/tableau-de-bord', element: <DashboardLayout />, children: [{ path: '', element: <DashboardApp /> }] },
    // { path: '/articles', element: <DashboardLayout />, children: [{ path: '', element: <Blog /> }] },
    // { path: '/articles/:id', element: <DashboardLayout />, children: [{ path: '', element: <BlogManage /> }] },
    { path: '/404', element: <LogoOnlyLayout />, children: [{ path: '', element: <NotFound /> }] },
    { path: '/login', element: <LogoOnlyLayout />, children: [{ path: '', element: <Login /> }] },
    // { path: '/register', element: <LogoOnlyLayout />, children: [{ path: '', element: <Register /> }] },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
