// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'tableau de bord',
    path: '/tableau-de-bord',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  // {
  //   title: 'utilisateurs',
  //   path: '/utilisateurs',
  //   icon: getIcon('eva:people-fill'),
  // },
  // {
  //   title: 'produits',
  //   path: '/produits',
  //   icon: getIcon('eva:shopping-bag-fill'),
  // },
  {
    title: 'articles',
    path: '/articles',
    icon: getIcon('eva:file-text-fill'),
  },
  {
    title: 'conferences',
    path: '/conferences',
    icon: getIcon('eva:file-text-fill'),
  },
];

export default navConfig;
